const get = require('lodash/get')
const compact = require('lodash/compact')

const splitPath = (path = '/') => {
  let consolidatedPath = path
  if (path === '/en') {
    consolidatedPath = '/cn'
  }

  return compact(consolidatedPath.split('/'))
}

const stripLocale = (path) => {
  const splittedPath = splitPath(path)
  return splittedPath.splice(1, splittedPath.length).join('/')
}

const pathEqual = (path, prevPath) => {
  return stripLocale(path) === stripLocale(prevPath)
}

exports.shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
}) => {
  try {
    const prevLocation = get(prevRouterProps, 'location')
    const location = get(routerProps, 'location')
    const prevPathname = get(prevLocation, 'pathname')
    const pathname = get(location, 'pathname')
    const prevHash = get(prevLocation, 'hash')
    const hash = get(location, 'hash')

    return !(pathEqual(pathname, prevPathname) && pathname && prevPathname && prevHash === hash)
  } catch (err) {
    return [0, 0]
  }
}

exports.onClientEntry = () => {
  let pathname = window.location.pathname
  const slugComponents = pathname.split("/")
  const possibleLang = get(slugComponents, 1)
  if (["cn", "en", "hk"].includes(possibleLang)) {
    return
  }
  const languageFlag = navigator.language.toLowerCase()
  let navigatorLanguage
  if (languageFlag === 'zh-tw') {
    navigatorLanguage = 'hk'
  } else if (languageFlag === 'zh-cn') {
    navigatorLanguage = 'cn'
  } else {
    navigatorLanguage = ''
  }

  const newUrl = new URL(`${navigatorLanguage}${window.location.pathname}`, `${window.location.origin}`)
  if (window.location.toString() === newUrl.toString()) {
    return;
  }
  else {
    window.location.replace(newUrl.toString())
  }
}